<script setup lang="ts"></script>

<template>
  <UContainer class="section flex flex-col sm:flex-row gap-5">
    <div class="w-fit sm:cy-account-bg py-5">
      <AccountSidebar />
    </div>
    <div class="flex-grow cy-account-bg p-5 sm:p-5">
      <slot />
    </div>
  </UContainer>
</template>
