<script setup lang="ts">
const navLink = ref("");
const props = defineProps({
  modelValue: String,
});

const emit = defineEmits(["update:modelValue"]);

function changeNav(link: string) {
  navLink.value = link;
  emit("update:modelValue", navLink.value);
}

const items = [
  {
    label: "profile",
    icon: "i-ph-user",
    // click: ()=>{changeNav('profile')}
    to: "/account",
  },
  {
    label: "wishlist",
    icon: "i-ph-bookmark-simple",
    // click: ()=>{changeNav('profile')}
    to: "/account/wishlist",
  },
  {
    label: "favourite",
    icon: "i-ph-heart",
    // click: ()=>{changeNav('profile')}
    to: "/account/favourite",
  },
  {
    label: "security",
    icon: "i-ph-lock",
    // click: ()=>{changeNav('security')}
    to: "/account/security",
  },
  // {
  //   label: "notification",
  //   icon: "i-ph-bell-light",
  //   // click: ()=>{changeNav('notification')}
  //   to: "/account?id=notification",
  // },
  {
    label: "orders",
    icon: "i-ph-list-checks-bold",
    // click: ()=>{changeNav('orders')}
    to: "/account/orders",
  },
];

const query = computed(() => useRoute().query.id);

const width_type = ref("large");

function changeWidth() {
  if (width_type.value == "large") {
    width_type.value = "small";
  } else {
    width_type.value = "large";
  }
}

//window size
const { width } = useWindowSize();
onMounted(() => {
  if (width.value < 768) {
    width_type.value = "small";
  }
});
</script>

<template>
  <div class="flex flex-col gap-4 w-fit" :class="[
    width_type == 'large' && 'sm:w-[180px] lg:w-[230px]',
    width_type == 'small' && 'sm:w-[90px]',
  ]">
    <div v-for="item of items" class="hidden sm:block px-3 py-1.5" :class="[
    query == item.label &&
    'border-r-[3px] border-primary text-primary bg-white dark:bg-gray-700/30',
    query != item.label &&
    'hover:text-primary hover:bg-white dark:hover:bg-gray-700/30',
  ]">
      <NuxtLink class="flex items-center gap-3" :class="{ 'justify-center': width_type == 'small' }" :to="item.to">
        <UTooltip v-if="width_type == 'small'" :text="item.label" :popper="{ placement: 'top' }">
          <UIcon class="w-5 h-5" :name="item.icon" />
        </UTooltip>
        <UIcon v-else class="w-5 h-5" :name="item.icon" />
        <span class="capitalize" :class="{ hidden: width_type == 'small' }">
          {{ item.label }}
        </span>
      </NuxtLink>
    </div>

    <UPopover class="sm:hidden" mode="hover">
      <UButton color="white" icon="i-heroicons-bars-3" />
      <template #panel>
        <div v-for="item of items" class="px-3 py-1.5 w-60 min-h-fit" :class="[
    query == item.label &&
    'border-r-[3px] border-primary text-primary bg-white dark:bg-gray-700/30',
    query != item.label &&
    'hover:text-primary hover:bg-white dark:hover:bg-gray-700/30',
  ]">
          <NuxtLink class="flex items-center gap-3" :to="item.to">
            <UIcon class="w-5 h-5" :name="item.icon" />
            <span class="capitalize">{{ item.label }}</span>
          </NuxtLink>
        </div>
      </template>
    </UPopover>

    <div class="hidden sm:flex px-3 my-10" :class="{ 'justify-center': width_type == 'small' }">
      <UIcon :name="width_type == 'large'
    ? 'i-heroicons-arrow-left-20-solid'
    : 'i-heroicons-arrow-right-20-solid'
    " class="w-5 h-5 cursor-pointer" @click="changeWidth" />
    </div>
  </div>
</template>

<style scoped></style>
